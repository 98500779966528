<template>
  <swiper :options="swiperOption" ref="mySwiper">
    <!-- slides -->
    <swiper-slide class="category-swiper__slide" v-for="category in categories" :key="category.id">
      <a
        class="categorys-swiper__slide-inner-container"
        :href="'/palkinnot?kategoria=' + category.key"
        data-turbolinks="false"
      >
        <div class="category-swiper__slide__image-container">
          <img class="category-swiper__slide__image" :src="category.image.url" />
        </div>
        <div class="category-swiper__slide__content">
          <h3 class="category-swiper__slide__title">{{category.name}}</h3>
          <div class="category-swiper__slide__description">{{category.description}}</div>
        </div>
      </a>
    </swiper-slide>

    <!-- Optional controls -->
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>

<script>
export default {
  data: function () {
    return {
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 10,
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        breakpoints: {
          400: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          680: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 10
          }
        }

        // Responsive breakpoints
        /*breakpoints: {
          // when window width is >= 320px
          500: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          750: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          // when window width is >= 480px
          1000: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          // when window width is >= 640px
          1200: {
            slidesPerView: 4,
            spaceBetween: 10
          }
        }*/
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  methods: {
    previousSlide() {
      console.log("PREVIOUS");
      this.swiper.slideTo(this.swiper.activeIndex - 1);
    },
    nextSlide() {
      console.log("NEXT");
      this.swiper.slideTo(this.swiper.activeIndex + 1);
    }
  },
  props: ['categories']
}
</script>

<style scoped>
  .category-swiper__slide {
    width: 230px;
  }
</style>
