<template>
  <div class="profile-menu">
      <div class="profile-menu__header">
        <button @click="open = !open" class="profile-menu__toggle"></button>
      </div>
      <ul class="profile-menu__items" v-show="open">
        <li v-for="navigationItem in navigationItems">
          <a :href="navigationItem.url">{{navigationItem.label}}</a>
        </li>
        <li><a rel="nofollow" data-method="delete" href="/users/sign_out">Kirjaudu ulos</a></li>
      </ul>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
        open: false
    }
  },
  props: ['email', 'navigationItems']
}
</script>

<style scoped>
</style>
