<template>
  <div class="mobile-navigation">
    <button @click="toggle()" class="mobile-navigation__toggle"></button>
    <div class="mobile-navigation__content" v-bind:class="{ 'mobile-navigation__content--open': open }">
      <button class="mobile-navigation__close" @click="open = false"></button>

      <ul class="mobile-navigation__profile" v-if="isLoggedIn">
        <li class="mobile-navigation__navigation-item" v-for="navigationItem in profileNavigationItems">
          <a :href="navigationItem.url" :data-turbolinks="!navigationItem.turbolinks ? 'false' : false">{{ navigationItem.label }}</a>
        </li>
      </ul>

      <ul class="mobile-navigation__main">
        <li class="mobile-navigation__navigation-item" v-for="navigationItem in mainNavigationItems">
          <a :href="navigationItem.url" :data-turbolinks="!navigationItem.turbolinks ? 'false' : true">{{ navigationItem.label }}</a>
        </li>
      </ul>

      <a class="mobile-navigation__log-out" v-if="isLoggedIn" rel="nofollow" data-method="delete" href="/users/sign_out">Kirjaudu ulos</a>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
        open: false
    }
  },
  methods: {
    toggle: function() {
      console.log('toggle');
      this.open = !this.open;
    }
  },
  props: ['mainNavigationItems', 'profileNavigationItems', 'isLoggedIn']
}
</script>

<style scoped>
</style>
