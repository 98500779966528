/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// app/javascript/packs/application.js
//import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import VueAwesomeSwiper from 'vue-awesome-swiper'

import ProfileMenu from '../components/ProfileMenu.vue';
import CategorySwiper from '../components/CategorySwiper.vue';
import TrainingsSearch from '../components/TrainingsSearch.vue';
import ProductPointsAccordian from '../components/ProductPointsAccordian.vue';
import MobileNavigation from '../components/MobileNavigation.vue';

// require styles
//import 'swiper/dist/css/swiper.css'

import moment from 'moment'
Vue.prototype.moment = moment;

Vue.filter('formatDate', function (date) {
  let day = moment(date)
  return day.format("DD.MM.YYYY");
})

Vue.use(VueAwesomeSwiper, /* { default global options } */)
Vue.component('profile-menu', ProfileMenu);
Vue.component('category-swiper', CategorySwiper);
Vue.component('trainings-search', TrainingsSearch);
Vue.component('product-points-accordian', ProductPointsAccordian);
Vue.component('mobile-navigation', MobileNavigation);
//Vue.use(TurbolinksAdapter)

$( document ).ready(function() {
    //console.log("VUE LOAD");
   const app = new Vue({
     el: '#vue-app'
   })
})

/*document.addEventListener('turbolinks:load', () => {
   const app = new Vue({
      el: '#vue-app',
   })
});*/