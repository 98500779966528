<template>
  <div class="trainings-search">
    <div class="training-search__filters-container">
      <label class="training-search__label">Näytä:</label>
      <ul class="trainigs-search__filters">
        <li
          class="trainigs-search__filters__filter"
          v-for="category in categoriesWithItems"
          @click="selectCategory(category)"
          v-bind:class="{ active: category.id == selectedCategory.id }"
          :key="category.id">
          <button>{{ category.name }}</button>
        </li>
      </ul>
    </div>

    <div class="training-search__trainings">
      <div v-for="training in visibleTrainings" :key="training.id">
        <a
          class="article-poster"
          :href="training.url"
          v-bind:style="{ backgroundImage: 'url(' + training.image + ')' }"
        >
          <div class="article-poster__article-content" >
            <h3 class="article-poster__date">
              <span v-if="training.category == 'Koulutus'">{{ training.date | formatDate }} | </span>
              {{ training.category }}
            </h3>
            <h2 class="article-poster__title">{{ training.title }}</h2>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  data: function() {
    return {
        selectedCategory: false
    }
  },
  computed: {
    visibleTrainings: function() {
      return this.trainings.filter(training => {
        return !this.selectedCategory || training.category == this.selectedCategory.name
      })
    },
    categoriesWithItems: function() {
      const uniqueCategoryNames = _.uniq(this.trainings.map(training => training.category));
      return this.categories.filter(category => {
        return uniqueCategoryNames.includes(category.name)
      });
    }
  },
  methods: {
    selectCategory: function(category) {
      this.selectedCategory = category;
    }
  },
  props: ['trainings', 'categories']
}
</script>

<style scoped>
</style>
