<template>
  <div class="product-points-accordian">
    <div class="product-points-accordian__heading" v-bind:class="{ 'product-points-accordian__heading--open': open }" @click="toggle()">{{ title }}</div>
    <div class="product-points-accordian__content" v-bind:class="{ 'product-points-accordian__content--open': open }">
      <table class="product-points-accordian__products-list" >
        <tr>
          <th>Tuote</th>
          <th class="product-points-accordian__points-column">Pisteet</th>
        </tr>
        <tr v-for="product in products" :key="product.id">
          <td>{{ product.name }}</td>
          <td class="product-points-accordian__points">{{ product.points_per_installation }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
        open: false
    }
  },
  methods: {
    toggle: function() {
      this.open = !this.open;
    }
  },
  props: ['products', 'title']
}
</script>

<style scoped>
</style>
